const industryArray = [
    {key:1, value:"重化学工業"},
    {key:2, value:"農林水産業"},
    {key:3, value:"鉱業"},
    {key:4, value:"建設業"},
    {key:5, value:"電気・ガス・熱供給・水道業"},
    {key:6, value:"IT・情報通信業"},
    {key:7, value:"運輸業・郵便業"},
    {key:8, value:"金融業・保険業"},
    {key:9, value:"不動産業"},
    {key:10, value:"士業・コンサルティング業"},
    {key:11, value:"宿泊業"},
    {key:12, value:"生活関連サービス業"},
    {key:13, value:"教育・学習支援業"},
    {key:14, value:"医療・福祉業"},
    {key:15, value:"公務員"},
    {key:16, value:"飲食業"},
    {key:17, value:"レジャー・娯楽業"},
    {key:18, value:"広告業"},
    {key:19, value:"印刷・出版業"},
    {key:20, value:"その他"},
];
const areaArray = [
  {key:1, value:"北海道"},
  {key:2, value:"青森県"},
  {key:3, value:"岩手県"},
  {key:4, value:"宮城県"},
  {key:5, value:"秋田県"},
  {key:6, value:"山形県"},
  {key:7, value:"福島県"},
  {key:8, value:"茨城県"},
  {key:9, value:"栃木県"},
  {key:10, value:"群馬県"},
  {key:11, value:"埼玉県"},
  {key:12, value:"千葉県"},
  {key:13, value:"東京都"},
  {key:14, value:"神奈川県"},
  {key:15, value:"新潟県"},
  {key:16, value:"富山県"},
  {key:17, value:"石川県"},
  {key:18, value:"福井県"},
  {key:19, value:"山梨県"},
  {key:20, value:"長野県"},
  {key:21, value:"岐阜県"},
  {key:22, value:"静岡県"},
  {key:23, value:"愛知県"},
  {key:24, value:"三重県"},
  {key:25, value:"滋賀県"},
  {key:26, value:"京都府"},
  {key:27, value:"大阪府"},
  {key:28, value:"兵庫県"},
  {key:29, value:"奈良県"},
  {key:30, value:"和歌山県"},
  {key:31, value:"鳥取県"},
  {key:32, value:"島根県"},
  {key:33, value:"岡山県"},
  {key:34, value:"広島県"},
  {key:35, value:"山口県"},
  {key:36, value:"徳島県"},
  {key:37, value:"香川県"},
  {key:38, value:"愛媛県"},
  {key:39, value:"高知県"},
  {key:40, value:"福岡県"},
  {key:41, value:"佐賀県"},
  {key:42, value:"長崎県"},
  {key:43, value:"熊本県"},
  {key:44, value:"大分県"},
  {key:45, value:"宮崎県"},
  {key:46, value:"鹿児島県"},
  {key:47, value:"沖縄県"},
  {key:48, value:"国外"}
];

const postArray = [
  {key:0, value:"代表取締役"},
  {key:1, value:"執行役員"},
  {key:2, value:"個人事業主"},
  {key:3, value:"起業・独立予定"},
];

const genderArray = [
  {key:0, value:"男性"},
  {key:1, value:"女性"},
  {key:2, value:"その他"},
];

const planArray = [
    {key:0, value:"フリープラン"},
    {key:1, value:"スタンダードプラン"},
    {key:2, value:"プレミアムプラン"},
  ];

  const accountArray = [
    {key:0, value:"普通"},
    {key:1, value:"当座"},
    {key:2, value:"貯蓄"},
  ];

  const ageArray = [
    {key:0, value:"0代"},
    {key:1, value:"10代"},
    {key:2, value:"20代"},
    {key:3, value:"30代"},
    {key:4, value:"40代"},
    {key:5, value:"50代"},
    {key:6, value:"60代"},
    {key:7, value:"70代"},
    {key:8, value:"80代"},
    {key:9, value:"90代"},
    {key:10, value:"100歳以上"},
  ];

const findValueByKey = (key,array) => {
    const item = array.find(item => item.key === key);
    return item ? item.value : "";
};


export {industryArray,areaArray,postArray,genderArray,planArray,findValueByKey,accountArray,ageArray}

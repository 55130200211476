// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table, Button, Modal, Select, Popover } from 'antd';
import { findValueByKey, planArray } from '../common';
// import Loading from "./Loading";

const ReferrerUsersListTable = (props) => {
  const {referrerUsers} = props;
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  // const [isLoad,setIsLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  useEffect(() => {
    setCurrentPage(1);
  },[referrerUsers]);

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });


  const columns = [
    { title: '名前', dataIndex: 'lastName', key: 'lastName',
      render: (data, record, index)=> {
        return (
          <>
          {referrerUsers[index+(currentPage-1)*pageSize] &&
            <>
              {data}{referrerUsers[index+(currentPage-1)*pageSize].firstName}
            </>
          }
          </>
        );
      }     
    },
    { title: 'プラン', dataIndex: 'plan', key: 'plan',
      render: (data)=> {
        return (
          <span>
            {data ? (
              <>{findValueByKey(data,planArray)}</>
            ) : (
              <>フリープラン</>
            )}
          </span>
        );
      }      
    },
    { title: '登録日', dataIndex: 'registerDate', key: 'registerDate',
      render: (data)=> {
        return (
          <span>
            {(new Date(data)).getFullYear()+"/"+((new Date(data)).getMonth()+1)+"/"+(new Date(data)).getDate()}
          </span>
        );
      }      
    },
  ];

  return (
    <>
        <Table dataSource={referrerUsers} columns={columns} bordered scroll={{ x: true, y:adjustedHeight }} 
          pagination={{ position: ['topRight','bottomRight'],
            current: currentPage,
            pageSize: pageSize,
            total: referrerUsers.length
          }} 
          onChange={handleTableChange}/>
    </>
   
  );
};

export default ReferrerUsersListTable;

import './App.css';
import React from "react";


const SuccessCancell = () => {

  return (
    <div className='body-content'>
      <div className='error-content'>
        <div>
          <p>有料プランの解約が完了しました。</p>
          <p>解約後も有料プランの期間終了日まではお使いいただけます。</p>
        </div>
      </div>
    </div>

  );
};

export default SuccessCancell;

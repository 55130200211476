import './App.css';
import React from "react";


const SuccessPlanChange = () => {

  return (
    <div className='body-content'>
      <div className='error-content'>
        <div>
          <p>プランの更新が完了しました。</p>
          <p>スタンダードプランからプレミアムプランへの切り替えの場合、スタンダードプランとプレミアムプランの差額分を日割り計算した金額が次回の更新日に上乗せして請求されます。</p>
          <div style={{backgroundColor:"#fff",padding:"5px"}}>
            <p>●請求例</p>
            <p>9月1日にスタンダードプランの支払いをした後、9月15日にプレミアムプランに切り替えた場合</p>
            <p>9月1日～9月14日はスタンダードプラン、9月15日～9月30日はプレミアムプランとなる</p>
            <p>そのため、（プレミアムプランの金額 - スタンダードプランの金額） × (9月でプレミアムプランを使用する日数 / 月の日数) を計算した金額が次回の支払いに上乗せして請求される</p>
            <p>(2400 - 1200) × (15 / 30) = 600 + 2400(プレミアムプランの10月分の支払い)</p>
            <p>次回請求金額は3000円となる</p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SuccessPlanChange
;

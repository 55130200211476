import './App.css';
import React from "react";


const SuccessPay = () => {

  return (
    <div className='body-content'>
      <div className='error-content'>
        <div style={{margin:"0 5px"}}>
          <p>有料プランへの更新が完了しました。</p>
          <p>公式LINEのトーク画面にもどりご利用方法をご確認ください。</p>
          <p>ご不明点やシステムの不具合などがございましたら、LINEトーク画面の下部メニューの「管理者LINE」からご連絡ください。</p>
        </div>
      </div>
    </div>

  );
};

export default SuccessPay;

import './App.css';
import React, { useEffect } from "react";

const FriendAddIntent = () => {
  useEffect(()=>{
    window.location.href = process.env.REACT_APP_ACOUNT_URL;
  },[]);
  return (
    <></>
  );
};

export default FriendAddIntent;

import './App.css';
import React, { useState, useEffect } from "react";
import { Form, Button, ConfigProvider, Checkbox, Input, Select} from 'antd';
import { useNavigate,useLocation, Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { findValueByKey, planArray } from './common';
import { Option } from 'antd/es/mentions';

// Stripeの公開可能キーを設定
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_OPEN_KEY);


const PlanChange = () => {
  const [userInfo, setUserInfo]  = useState(null);
  const [checkedItems, setCheckedItems] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [stripe, setStripe] = useState(null);
  const [mail,setMail] = useState(null);
  const [selectPlan,setSelectPlan] = useState(0);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashLINEID = queryParams.get('id');
  useEffect(()=>{
    if(hashLINEID === null){
      navigate('/Error');
    }
    init();
    
  },[]);

  const init = async() =>{
    if(await checkLINELogin()){
      if(await startLogin()){
        await getUserInfo();
      }
      else{
        navigate('/Error');
      }
      
    }
    else{
      await startLINEAuth();
    }
  }


  const handleCheckboxChange = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.name]: e.target.checked,
    });
  };

  const allChecked = Object.values(checkedItems).every(Boolean);

  async function getStripe(LINEID){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/stripe', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({LINEID}),
      });

      const data = await response.json();
      if(data.isError){
        navigate('/Error');
      }
      if(data.stripeData){
        setStripe(data.stripeData);
        setMail(data.stripeData.mail ? data.stripeData.mail : "");
        setSelectPlan(data.stripeData.plan ? data.stripeData.plan : 0);
      }
      else{
        setStripe({});
      }
  } catch (error) {
      navigate('/Error');
  }
}

  async function getUserInfo(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({userId:hashLINEID}),
      });

      const data = await response.json();
      if(data){
        if(data.isError){
          navigate("/Error")
        }
        else{
          if(data.userInfo !== null){
            let userInfo = data.userInfo;
            if(userInfo.isEntry && userInfo.isAuth){
              setUserInfo({
                LINEID:userInfo.LINEID,
                lastName: userInfo.lastName,
                firstName: userInfo.firstName,
              });
              await getStripe(userInfo.LINEID);
            }
            else{
              navigate("/Error");
            }
        }
        else{
          navigate("/Error");
        }
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLINEAuth(){
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
  const state = generateRandomString(8);
  const scope = 'profile openid';
  const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
  window.location.href = authUrl;
}

function generateRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

async function checkLINELogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({accessOringn:"/PlanChange?id=",parameter:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

const handleSelectPlan =(value)=>{
  setSelectPlan(value);
}

  const onSubmit = async() => {

    if(selectPlan == 0){
      window.location.href = `/PlanCancell?id=${hashLINEID}`;
    } 
    else{
      const postData = {
        lastName:userInfo.lastName,
        firstName:userInfo.firstName,
        LINEID: userInfo.LINEID,
        mail: mail,
        plan:selectPlan,
        customerId:stripe ? stripe.customerId :null,
        subscriptionId:stripe ? stripe.subscriptionId : null
      }
      try {
          const response = await fetch(process.env.REACT_APP_API_DIR+'/stripe/billing', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(postData),
          });
  
          const session = await response.json();
  
          const stripe = await stripePromise;
          
          if(session.isPlanChange){
            navigate('/SuccessPlanChange');
          }
          else{
            const { error } = await stripe.redirectToCheckout({
              sessionId: session.id,
            });
        
            if (error) {
              navigate('/Error');
              console.error('Error during redirect to checkout:', error);
            }
          }
 
      } catch (error) {
          navigate('/Error');
          console.error('Fetch error:', JSON.stringify(error));
      }
    }
  };

  return (
    <Elements stripe={stripePromise}>
    {userInfo !== null &&
      <div className='main-content'>
        <div style={{margin:"0 40px"}}>
        <h2 className='page-title'>プラン変更</h2>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Input: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Radio:{
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Select: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
                Checkbox: {
                  colorPrimary: '#07DFD8',
                  algorithm: true,
                },
              },
            }}
          >

           {stripe &&
           <Form
            name="form"
            className="page-form"
            style={{margin:"20px auto"}}
            onFinish={onSubmit}
          >
          <div>ようこそ、{userInfo.lastName}{userInfo.firstName}さん</div>
          <div>現在は{stripe.plan ? findValueByKey(stripe.plan,planArray): "フリープラン"}です。</div>
          <div>
            {(stripe && stripe.plan > 0) && (
              !stripe.isCancellPlan ? (
                <>次回更新日：{(new Date(stripe.planNextDate)).getFullYear() + "/" + ((new Date(stripe.planNextDate)).getMonth()+1) + "/" + (new Date(stripe.planNextDate)).getDate()}</>
              ) : (
                <>解約予定日：{(new Date(stripe.planNextDate)).getFullYear() + "/" + ((new Date(stripe.planNextDate)).getMonth()+1) + "/" + (new Date(stripe.planNextDate)).getDate()}</>
              )
            )
            }
          </div>
          <div style={{margin:"20px auto",backgroundColor:"#fff",padding:"10px"}}>
              <div style={{margin:"10px 0"}}>
                <div className='plan-title'>フリープラン【無料】</div>
                <div>おつなぎくんに登録されているユーザーの中からランダムでユーザーのプロフィールを<span style={{color:"red",fontWeight:"bold"}}>毎月4名以上</span>送信します。送信された中から繋がりたい方を選ぶことができます。</div>
              </div>
              <div style={{margin:"10px 0"}}>
                <div className='plan-title'>スタンダードプラン【1200円(税込み)/月】</div>
                <div>フリープランの内容＋おつなぎくんに登録しているユーザーの一覧リストを閲覧可能、さらにそのリストの中から<span style={{color:"red",fontWeight:"bold"}}>繋がりたい方を毎月5名まで自由に選ぶことができます。</span></div>
                <div style={{fontSize:"90%"}}>※最低保証人数：2人</div>
              </div>
              <div style={{margin:"10px 0"}}>
                <div className='plan-title'>プレミアムプラン【2400円(税込み)/月】</div>
                <div>フリープランの内容＋おつなぎくんに登録しているユーザーの一覧リストを閲覧可能、さらにそのリストの中から<span style={{color:"red",fontWeight:"bold"}}>繋がりたい方を毎月15名まで自由に選ぶことができます。</span></div>
                <div style={{fontSize:"90%"}}>※最低保証人数：６人</div>
              </div>
              <div style={{fontSize:"90%"}}>※最低保証人数・・・スタンダードプラン、プレミアムプランで上限まで繋がりたい方を選んだが、最終的に繋がれた人数が最低保証人数未満の場合は、繋がった人数と最低保証人数との差分の人数と繋がるまで繋がりたい方を選択できるようにいたします。もしこの条件に当てはまる場合はご連絡ください。</div>
          </div>
          <div>　</div>
          <div>下記よりプランの変更をお願いします。</div>
          <div style={{color:"red",textAlign:"end",fontSize:"90%"}}>＊必須項目</div>
              <Form.Item
                name="mail"
                label={<span style={{fontSize:"120%"}}>メールアドレス</span>}
                rules={[{ required: true, message: 'メールアドレスを入力してください' }]}
                labelCol={{span:24}}
                initialValue={mail}
              >
                <Input
                  style={{fontSize:"120%"}}
                  onChange={e=> setMail(e.target.value)}
                  type="text"
                />
              </Form.Item>
              <Form.Item
                name="plan"
                label={<span style={{fontSize:"120%"}}>プランを選択</span>}
                rules={[{ required: true, message: 'プランを選択してください' }]}
                labelCol={{span:24}}
                initialValue={stripe.plan ? stripe.plan : 0}
              >
                <Select
                  placeholder="プランを選択してください"
                  onChange={e=> handleSelectPlan(e)}
                  value={selectPlan}
                >
                  {planArray.map(item => (
                    <Option key={item.key} value={item.key}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div style={{marginBottom:"20px"}}>
                <Checkbox
                  name="checkbox1"
                  checked={checkedItems.checkbox1}
                  onChange={handleCheckboxChange}
                >
                  過度な営業・勧誘行為は行いません
                </Checkbox>
              </div>
              <div style={{marginBottom:"20px"}}>
                <Checkbox
                  name="checkbox2"
                  checked={checkedItems.checkbox2}
                  onChange={handleCheckboxChange}
                >
                  宗教・MLM・投資・チームビルディング等の勧誘は行いません
                </Checkbox>
              </div>
              <div style={{marginBottom:"20px"}}>
                <div style={{background:"#fff",height:"100px",overflowY:"scroll",padding:"5px"}}>
                  <h3>個人情報の取り扱いに関する同意事項</h3>
                  <h4>1. 個人情報の定義</h4>
                  <div>本サービスにおける個人情報とは、氏名、生年月日、性別、などの個人を識別できる情報を指します。</div>
                  <h4>2. 個人情報の利用目的</h4>
                  <div>ご提供いただいた個人情報は、以下の目的で利用いたします。</div>
                  <div>・他のユーザーへのプロフィール情報の公開</div>
                  <div>・ユーザーサポート、問い合わせ対応のため</div>
                  <div>・サービス向上のためのデータ分析および統計処理</div>
                  <h4>3. プロフィール情報の公開</h4>
                  <div>ユーザーが本サービスで提供するプロフィール情報（LINEURL、生年月日以外の上記で入力した内容）は、他のユーザーに対して公開されます。ただし、次の情報は他のユーザーに公開されません。</div>
                  <div>・LINEのアカウントに関連する情報</div>
                  <div>生年月日は公開されませんが、年代(20代、30代など)の情報は公開されます。</div>
                  <div>本サービスの公式LINEアカウントをブロックすることで他ユーザーへのプロフィール情報の公開が停止されます。</div>
                  <h4>4. 第三者への提供</h4>
                  <div>ご提供いただいた個人情報は、以下の場合を除き、ユーザーの同意なく第三者に提供することはありません。</div>
                  <div>・ユーザーの同意がある場合</div>
                  <div>・法令に基づく場合</div>
                  <div>・ユーザー保護やサービス運営のため、必要な範囲内で業務委託先に提供する場合</div>
                  <h4>5. 個人情報の管理</h4>
                  <div>ご提供いただいた個人情報は、適切な管理のもとで安全に保管され、個人情報の漏洩、紛失、改ざんなどを防止するため、必要かつ適切な措置を講じます。</div>
                  <h4>6. 個人情報の開示、訂正、削除</h4>
                  <div>ユーザーは、サービス内の設定を通じて、自己の個人情報を確認・修正・削除することができます。その他の開示、訂正、削除の要求がある場合は、サポート窓口にて対応いたします。</div>
                  <h4>7. 利用停止および退会</h4>
                  <div>ユーザーが本アプリの利用を停止または退会する場合、一定の期間を経てすべての個人情報が削除されます。ただし、法令に基づく保存義務がある場合はこの限りではありません。</div>
                  <h4>8. 同意の確認</h4>
                  <div>以下のチェックボックスにチェックを入れることで、上記の個人情報の取り扱いに関する内容に同意いただいたものとみなします。</div>
                </div>
                <div style={{marginTop:"5px"}}>
                  <Checkbox
                    name="checkbox3"
                    checked={checkedItems.checkbox3}
                    onChange={handleCheckboxChange}
                  >
                    上記の個人情報の取り扱いに関する内容に同意します
                  </Checkbox>
                </div>
              </div>
              <div style={{marginTop:"40px"}}>有料プランを解約する場合はフリープランに変更をお願い致します。</div>
              <div>おつなぎくん自体を解約したい場合はフリープランに切り替えた後おつなぎくんの公式LINEをブロックしてください。</div>
              <div style={{margin:"20px auto"}}>
                <div className="form-button" style={{marginTop:"5px"}}>
                    <Button type="primary" htmlType="submit" disabled={!allChecked || (stripe.plan ? (selectPlan === stripe.plan) : (selectPlan === 0))}>
                      プランを変更する
                    </Button>
                </div>
              </div>
            </Form>
           } 
           <div style={{textAlign:"end",fontSize:"90%"}}>
           </div>
          </ConfigProvider>
        </div>
      </div>
    }
  </Elements>
  );
};

export default PlanChange;

import './App.css';
import React, { useState, useEffect } from "react";
import { Button, Checkbox, ConfigProvider, Modal} from 'antd';
import { useNavigate,useLocation, Link } from 'react-router-dom';


const PlanCancell = () => {
  const [userInfo, setUserInfo]  = useState(null);
  const [stripe, setStripe] = useState(null);
  const [isModal,setIsModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    checkbox: false,
  });

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashLINEID = queryParams.get('id');
  useEffect(()=>{
    if(hashLINEID === null){
      navigate('/Error');
    }
    init();
    
  },[]);

  const init = async() =>{
    if(await checkLINELogin()){
      if(await startLogin()){
        await getUserInfo();
      }
      else{
        navigate('/Error');
      }
      
    }
    else{
      await startLINEAuth();
    }
  }

  async function getStripe(LINEID){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/stripe', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({LINEID}),
      });

      const data = await response.json();
      if(data.isError){
        navigate('/Error');
      }
      if(data.stripeData){
        setStripe(data.stripeData);
      }
      else{
        setStripe({});
      }
  } catch (error) {
      navigate('/Error');
  }
}

  async function getUserInfo(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({userId:hashLINEID}),
      });

      const data = await response.json();
      if(data){
        if(data.isError){
          navigate("/Error")
        }
        else{
          if(data.userInfo !== null){
            let userInfo = data.userInfo;
            if(userInfo.isEntry && userInfo.isAuth){
              setUserInfo({
                LINEID:userInfo.LINEID,
                lastName: userInfo.lastName,
                firstName: userInfo.firstName,
                plan:userInfo.plan
              });
              await getStripe(userInfo.LINEID);
            }
            else{
              navigate("/Error");
            }
        }
        else{
          navigate("/Error");
        }
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLINEAuth(){
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
  const state = generateRandomString(8);
  const scope = 'profile openid';
  const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
  window.location.href = authUrl;
}

function generateRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

async function checkLINELogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({accessOringn:"/PlanCancell?id=",parameter:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

const handlePopup = () => {
  setIsModal(true);
};

const handleCancel = () => {
  setIsModal(false);
}

const handleCheckboxChange = (e) => {
  setCheckedItems({
    ...checkedItems,
    [e.target.name]: e.target.checked,
  });
};


  const onSubmit = async() => {
    setIsModal(false);
    const postData = {
      LINEID: userInfo.LINEID,
      subscriptionId:stripe.subscriptionId,
    }
    try {
        const response = await fetch(process.env.REACT_APP_API_DIR+'/stripe/cancell', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            navigate("/Error")
          }
          else{
            navigate(`/SuccessCancell`)
        }
      }
      else{
        navigate("/Error")
      }
    } catch (error) {
        navigate('/Error');
        console.error('Fetch error:', JSON.stringify(error));
    }
  };

  const allChecked = Object.values(checkedItems).every(Boolean);

  return (
    <>
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#07DFD8',
            algorithm: true,
          },
          Input: {
            colorPrimary: '#07DFD8',
            algorithm: true,
          },
          Radio:{
            colorPrimary: '#07DFD8',
            algorithm: true,
          },
          Select: {
            colorPrimary: '#07DFD8',
            algorithm: true,
          },
          Checkbox: {
            colorPrimary: '#07DFD8',
            algorithm: true,
          },
        },
      }}
    >
    {userInfo !== null &&
      <div className='main-content'>
        <div style={{margin:"0 40px"}}>
        <h2 className='page-title'>有料プラン解約</h2>

           {stripe &&
            <div style={{margin:"20px auto"}}>
              <div>有料プランの解約をする場合は下記の注意事項をお読みの上実行してください。</div>
              <div style={{margin:"20px 0"}}>
                <div style={{background:"#fff",height:"200px",overflowY:"scroll",padding:"5px"}}>
                  <h4>解約に関する同意事項</h4>
                  <div style={{fontSize:"90%"}}>
                    <div>・有料会員を解約すると有料会員の招待料を受け取ることができなくなります</div>
                    <div>・有料会員を解約すると現在出金していない有料会員分の招待料が破棄されます</div>
                    <div>・以下のチェックボックスにチェックを入れることで、上記の内容に同意いただいたものとみなします</div>
                  </div>

                </div>
                <div style={{marginTop:"5px"}}>
                  <Checkbox
                    name="checkbox"
                    checked={checkedItems.checkbox}
                    onChange={handleCheckboxChange}
                  >
                    上記の解約に関する内容に同意します
                  </Checkbox>
                </div>
              </div>
              <div style={{margin:"20px auto"}}>
                <div className="form-button" style={{marginTop:"5px"}}>
                  {(stripe && stripe.plan == 0) ?
                    (
                      <>未申込</>
                    ) : (
                      <Button type="primary" onClick={() => handlePopup()} disabled={!allChecked}>
                        解約する
                      </Button>
                    )
                  }

                </div>
              </div>
            </div>
           } 
           <div style={{textAlign:"end",fontSize:"90%"}}>
            <Link to={`/PlanChange?id=${hashLINEID}`} style={{color: '#07DFD8',textDecoration:"underline"}}>プラン申し込みに戻る</Link>
           </div>
        </div>
      </div>
    }
      <Modal
        title="確認"
        open={isModal}
        onCancel={() => handleCancel()}
        footer={[
          <>
          {
            <div style={{textAlign:"start"}}>
              本当に解約されますか？
            </div>
          }
              <Button key="cancel" onClick={() => handleCancel()}>
                閉じる
              </Button>
              <Button type="primary" onClick={() => onSubmit()}>
                解約する
              </Button>
          </>
        ]}
      >
      </Modal>
    </ConfigProvider>
    </>
  );
};

export default PlanCancell;

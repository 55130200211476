import './App.css';
import React, { useState, useEffect } from "react";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import { ReferrerUsersListTable } from './components';


const ReferrerUsersList = () => {
  const [referrerUsers,setReferrerUsers] = useState([]);
  const [name,setName] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashLINEID = queryParams.get('id');
  useEffect(()=>{
    if(hashLINEID === null){
      navigate('/Error');
    }
    init();
    
  },[]);

  const init = async() =>{
    if(await checkLINELogin()){
      if(await startLogin()){
        await setReferrerData();
      }
      else{
        navigate('/Error');
      }
      
    }
    else{
      await startLINEAuth();
    }
  }

  async function setReferrerData(){
    try {
      const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/get/referrer/list', {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({hashLINEID:hashLINEID}),
      });

      const data = await response.json();
      if(data){
        if(data.isError){
          navigate("/Error")
        }
        else{
          if(data.referrerUsers){
            setReferrerUsers(data.referrerUsers);
          }
          if(data.fullName){
            setName(data.fullName);
          }
      }
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

async function startLINEAuth(){
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback`);
  const state = generateRandomString(8);
  const scope = 'profile openid';
  const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
  window.location.href = authUrl;
}

function generateRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

async function checkLINELogin(){
  try {
    const response = await fetch(process.env.REACT_APP_API_DIR+'/login/check', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({accessOringn:"/ReferrerUsersList?id=",parameter:hashLINEID}),
    });

    const data = await response.json();
    if(data){
      return data.isLogin;
    }
    else{
      return false;
    }
  } catch (error) {
      navigate('/Error');
      console.error('Fetch error:', JSON.stringify(error));
  }
}

  return (
    <div className='back-content'>
      
      <div className='body-content'>
        <div className='table-content'>
          <h2 className='page-title'>招待者一覧</h2>
          <div>{name}さんが招待した方は<span style={{fontWeight:"bold"}}>{referrerUsers.length}人</span>です。</div>
          <div>招待したはずの方が表示されない場合は登録されているか確認しますのでご連絡ください。</div>
          <div style={{textAlign:"end"}}><Link to={`/Withdrawal?id=${hashLINEID}`} style={{color: '#07DFD8',textDecoration:"underline"}}>出金依頼はこちら</Link></div>
          {referrerUsers.length > 0 ? (
            <ReferrerUsersListTable referrerUsers={referrerUsers}/>
          ) : (
            <></>
          )}
        </div>
        
      </div>
    </div>
  );
};

export default ReferrerUsersList;

// src/DataTable.js
import './components.css';
import React, { useState, useEffect, useLayoutEffect} from "react";
import { Table, Button, Modal, Select, Popover } from 'antd';
// import Loading from "./Loading";
import { useNavigate } from 'react-router-dom';
import { industryArray,areaArray,postArray,genderArray,planArray,findValueByKey} from '../common';
import { SearchOutlined } from '@ant-design/icons';

const statusArray =[
  {key:0,value:"承認済み"},
  {key:1,value:"承認待ち"},
  // {key:2,value:"未登録"}
]

const UsersListAdminTable = () => {
  const [adjustedHeight, setAdjustedHeight] = useState(0);

  // const [isLoad,setIsLoad] = useState(false);
  const [usersArray,setUsersArray] = useState([]);
  const [allUsersArray,setAllUsersArray] = useState([]);
  const [filterUsersArray,setFilterUsersArray] = useState([]);
  const [isModal,setIsModal] = useState(false);
  const [selectIndex,setSelectIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const {Option} = Select;

  const [selectedPost,setSelectedPost] = useState(null);
  const [isDetail,setIsDetail] = useState(false);
  const [isAuth,setIsAuth] = useState(false);
  const [isDelete,setIsDelete] = useState(false);
  const [isBreach,setIsBreach] = useState(false);
  const [lastDate,setLastDate] = useState(null);
  const [nextDate,setNextDate] = useState(null);
  const [status,setStatus] = useState(0);
  // const [searchDateRange, setSearchDateRange] = useState([]);

  // const handleDateSearch = (dates) => {
  //   setSearchDateRange(dates);
  // };

  // const filterDataSourceByDate = (data) => {
  //   if (!searchDateRange || searchDateRange.length < 1) {
  //     setTableDataArray(data);
  //     return;
  //   }

  //   const [start, end] = searchDateRange;
  //   setTableDataArray( data.filter(item => {
  //     const itemDate = new Date(item.date);
  //     (start.$d).setHours(0,0,0,0,0);
  //     (end.$d).setHours(0,0,0,0,0);
  //     return itemDate.getTime() >= (start.$d).getTime() && itemDate.getTime() <= (end.$d).getTime();
  //   }));
  // };

  // const resetSearchDate = () => {
  //   setSearchDateRange([]);
  // }

  // const [selectedFilters, setSelectedFilters] = useState([]);

  // // 動的に名前のフィルターを生成
  // const uniqueNames = Array.from(new Set(postArray.map(item => item.value)));

  // // 動的にアドレスのフィルターを生成
  // const uniqueAddresses = Array.from(new Set(dataSource.map(item => item.address)));
  // const addressFilters = uniqueAddresses.map(address => ({ text: address, value: address }));

  const handleStatus = (value) =>{
    setStatus(value);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };


  const handleDetail = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsDetail(true);
  }

    const handleBreach = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize);
    setIsModal(true);
    setIsBreach(true);
  }

  const handleAuth = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsAuth(true);
  }

  const handleDelete = (index) => {
    setSelectIndex(index+(currentPage-1)*pageSize)
    setIsModal(true);
    setIsDelete(true);
  }

  const handleCancel = () => {
    setIsModal(false);
    setIsAuth(false);
    setIsDetail(false);
    setIsDelete(false);
    setIsBreach(false);
  }

  const updateIsAuth = async() =>{
    setIsModal(false);
    setIsAuth(false);
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/isAuth', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({LINEID:filterUsersArray[selectIndex].LINEID}),
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
            alert("エラーが発生しました");
          }
          else{
            alert("承認しました");
            window.location.reload();
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        alert("エラーが発生しました");
    }
  }

  const deleteUser = async() =>{
    setIsModal(false);
    setIsDelete(false);
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/delete', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({LINEID:filterUsersArray[selectIndex].LINEID}),
        });
        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
            alert("エラーが発生しました");
          }
          else{
            alert("削除しました");
            window.location.reload();
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        alert("エラーが発生しました");
    }
  }

    const breachUser = async() =>{
    setIsModal(false);
    setIsBreach(false);
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+'/profile/breach', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ isBreach:!filterUsersArray[selectIndex].isBreach ,LINEID:filterUsersArray[selectIndex].LINEID}),
        });
        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
            alert("エラーが発生しました");
          }
          else{
            alert("更新しました");
            window.location.reload();
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
        alert("エラーが発生しました");
    }
  }

  useEffect(() => {
    getAllUsers();
  },[]);

  useEffect(() => {
    fetchData(status);
  },[status]);

  const getAllUsers = async()=>{
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+"/users/all", {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setAllUsersArray(data.usersArray)
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  }

  const fetchData = async (status) => {
    let endpoint=""
    switch(status){
      case 0:
        endpoint = "/users";
        break;
      case 1:
        endpoint="/users/awaiting";
        break;
      case 2:
        endpoint="/users/noRegister";
        break;
    }
    try {
      // setIsLoad(true);
        const response = await fetch(process.env.REACT_APP_API_DIR+endpoint, {
          method: 'GET',
          mode: 'cors',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
        });

        const data = await response.json();
        if(data){
          if(data.isError){
            console.log(data.errorText);
          }
          else{
            setUsersArray(data.usersArray);
          }
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }
    // setIsLoad(false);
  };

  useEffect(() => {
    setFilterUsersArray(usersArray)
  },[usersArray]);

  useEffect(() => {
    setCurrentPage(1);
  },[filterUsersArray]);

  useEffect(() => {
    const item = filterUsersArray[selectIndex];
    if(item){
      const today = new Date();
      const todayDate = today.getDate();
      const registerDateObj = new Date(item.registerDate);
      const registerDate = registerDateObj.getDate();
      
      let lastMonthRegisterDate, nextMonthRegisterDate;
    
      // 年と月を跨いだ場合も考慮する
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      if(today < registerDate){
        // 今日の日付が登録日より前の場合、先月と今月の日付を表示
        const lastMonth = new Date(currentYear, currentMonth - 1, registerDate + 1); // 先月
        const thisMonth = new Date(currentYear, currentMonth, registerDate); // 今月
        lastMonthRegisterDate = `${lastMonth.getFullYear()}/${lastMonth.getMonth() + 1}/${lastMonth.getDate()}`;
        nextMonthRegisterDate = `${thisMonth.getFullYear()}/${thisMonth.getMonth() + 1}/${thisMonth.getDate()}`;
      }
      else{
        // 今日の日付が登録日より後の場合、今月と来月の日付を表示
        const thisMonth = new Date(currentYear, currentMonth, registerDate + 1); // 今月
        const nextMonth = new Date(currentYear, currentMonth + 1, registerDate); // 来月
        lastMonthRegisterDate = `${thisMonth.getFullYear()}/${thisMonth.getMonth() + 1}/${thisMonth.getDate()}`;
        nextMonthRegisterDate = `${nextMonth.getFullYear()}/${nextMonth.getMonth() + 1}/${nextMonth.getDate()}`;
      }
      setLastDate(lastMonthRegisterDate);
      setNextDate(nextMonthRegisterDate);
    }
  },[selectIndex]);

  const handlePostChange = (key) => {
    setSelectedPost(key);
    if (key == -1) {
      setFilterUsersArray(usersArray);
    } else {
      const filtered = usersArray.filter(item => item.post === key);
      setFilterUsersArray(filtered);
    }
  };

  useLayoutEffect(() => {
    function setTableSize(){
      const bodyRect = document.querySelector('.ant-table-body').getBoundingClientRect();
      const htmlRect = document.documentElement.getBoundingClientRect();
      setAdjustedHeight(window.innerHeight-(bodyRect.y-htmlRect.top));
    }
    setTableSize();
  
    const handleResize = () => {
      setTableSize();
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  function getDecade(birthDate) {
    const today = new Date();
    const birth = new Date(birthDate);
    
    // 年齢を計算
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    
    // 誕生日がまだ来ていない場合、年齢を1引く
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }
    
    // 年齢から年代を計算 (例: 27歳 => "20代", 35歳 => "30代")
    const decade = Math.floor(age / 10) * 10;
    
    return `${decade}代`;
  }

  function getReferrerUser(LINEID){
    if(LINEID){
      const index = allUsersArray.findIndex(item => item.LINEID == LINEID);
      const user = allUsersArray[index];
      return user.lastName ? user.lastName+user.firstName : user.LINENAME;
    }
    else{
      return "";
    }


  }

  const postFilterContent = (
    <Select
      placeholder="選択してください"
      style={{ width: 120 }}
      onChange={handlePostChange}
      value={selectedPost}
    >
      <Option value={-1}>すべて</Option>
      {postArray.map(item => (
        <Option key={item.key} value={item.key}>
          {item.value}
        </Option>
      ))}
    </Select>
  );

  const columns = [
    { title: '名前', dataIndex: 'lastName', key: 'lastName',
      render: (data, record, index)=> {
        return (
          <>
          {filterUsersArray[index+(currentPage-1)*pageSize] &&
            <>
              {data}{filterUsersArray[index+(currentPage-1)*pageSize].firstName}
            </>
          }
          </>
        );
      }     
    },
    { title: '会社名', dataIndex: 'companyName', key: 'companyName'},
    { title: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        役職
        <Popover
          content={postFilterContent}
          trigger="click"
          placement="bottom"
        >
          <SearchOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
        </Popover>
      </div>
    ),
     dataIndex: 'post', key: 'post',
      render: (data)=> {
        return (
          <span>
            {findValueByKey(data,postArray)}
          </span>
        );
      }      
    },
    { title: '性別', dataIndex: 'gender', key: 'gender',
      render: (data)=> {
        return (
          <span>
            {findValueByKey(data,genderArray)}
          </span>
        );
      }      
    },
    { title: '年代', dataIndex: 'birthDate', key: 'birthDate',
      render: (data)=> {
        return (
          <span>
            {getDecade(data)}
          </span>
        );
      }      
    },
    { title: '活動地域', dataIndex: 'area', key: 'area',
      render: (data)=> {
        return (
          <span>
            {findValueByKey(data,areaArray)}
          </span>
        );
      }      
    },
    { title: '業種', dataIndex: 'industry', key: 'industry',
      render: (data)=> {
        return (
          <span>
            {findValueByKey(data,industryArray)}
          </span>
        );
      }      
    },
    { title: '事業概要', dataIndex: 'businessSummary', key: 'businessSummary'},
    { title: 'プラン', dataIndex: 'plan', key: 'plan',
      render: (data)=> {
        return (
          <span>
            {findValueByKey(data,planArray)}
          </span>
        );
      }      
    },
    { title: '詳細', dataIndex: 'detail', key: 'detail',
      render: (data, record, index)=> {
        return (
          <Button type="primary" onClick={() => handleDetail(index)}>
            詳細
          </Button>
        );
      }      
    },
    { title: '承認', dataIndex: 'isAuth', key: 'isAuth',
      render: (data, record, index)=> {
        return (
          <>
            {data ? (
              <></>
            ) : (
              <Button type="primary" onClick={() => handleAuth(index)}>
                承認
              </Button>
            )}
          </>
        );
      }      
    },
    { title: '削除', dataIndex: 'delete', key: 'delete',
      render: (data, record, index)=> {
        return (
          <Button type="default" onClick={() => handleDelete(index)}>
            削除
          </Button>
        );
      }      
    },
    { title: '違反', dataIndex: 'isBreach', key: 'isBreach',
      render: (data, record, index)=> {
        return (
          <>
            {data ? (
              <Button type="default" onClick={() => handleBreach(index)}>
                違反解除
              </Button>
              ) : (
                <Button type="primary" onClick={() => handleBreach(index)}>
                  違反
                </Button>
              )}
          </>


        );
      }      
    },

  ];

  return (
    <>
        <Modal
          // title="詳細"
          open={isModal}
          onCancel={() => handleCancel()}
          footer={[
            <>
              {isDetail &&
                <div style={{wordWrap:"break-word"}}>
                {(filterUsersArray.length > 0 && isModal) &&
                  <div style={{textAlign:"start",margin:"20px 5px"}}>
                    <div style={{margin:"3px 0"}}>【名前】{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}</div>
                    <div style={{margin:"3px 0"}}>【LINEID】{filterUsersArray[selectIndex].LINEID}</div>
                    <div style={{margin:"3px 0"}}>【LINE名】{filterUsersArray[selectIndex].LINENAME}</div>
                    <div style={{margin:"3px 0"}}>
                      <div>【LINEアイコン】</div>
                      <div style={{marginLeft:"15px"}}>{filterUsersArray[selectIndex].LINEICON}</div>
                    </div>
                    <div style={{margin:"3px 0"}}>【LINEURL】{filterUsersArray[selectIndex].LINEURL}</div>
                    <div  style={{margin:"3px 0"}}>
                      <div>【自己紹介文】</div>
                      <div style={{marginLeft:"15px",whiteSpace:"pre-wrap"}}>{filterUsersArray[selectIndex].introductionText}</div>
                    </div>
                    <div style={{margin:"3px 0"}}>【招待者】{getReferrerUser(filterUsersArray[selectIndex].referrerLINEID)}</div>
                    {filterUsersArray[selectIndex].isDelete ? <span style={{color:"red"}}>ブロック済み</span> : <></>}
                    <div style={{margin:"5px 0"}}>
                      <div>期間：{lastDate} ~ {nextDate}</div>
                      <div style={{margin:"3px 0"}}>【紹介された数】{filterUsersArray[selectIndex].wasIntroducedNum}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請数】{filterUsersArray[selectIndex].requestNum}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎが承認された数】{filterUsersArray[selectIndex].wasAcceptdNum}</div>
                      <div style={{margin:"3px 0"}}>【自分のプロフィールの拡散数】{filterUsersArray[selectIndex].introduceNum}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請を受けた数】{filterUsersArray[selectIndex].wasRequestedNum}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請を承認した数】{filterUsersArray[selectIndex].acceptNum}</div>
                    </div>
                    <div style={{margin:"5px 0"}}>
                      <div>全期間</div>
                      <div style={{margin:"3px 0"}}>【紹介された数】{filterUsersArray[selectIndex].wasIntroducedNumTotal}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請数】{filterUsersArray[selectIndex].requestNumTotal}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎが承認された数】{filterUsersArray[selectIndex].wasAcceptdNumTotal}</div>
                      <div style={{margin:"3px 0"}}>【自分のプロフィールの拡散数】{filterUsersArray[selectIndex].introduceNumTotal}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請を受けた数】{filterUsersArray[selectIndex].wasRequestedNumTotal}</div>
                      <div style={{margin:"3px 0"}}>【お繋ぎ申請を承認した数】{filterUsersArray[selectIndex].acceptNumTotal}</div>
                    </div>
                  </div>
                }
                <Button key="cancel" onClick={() => handleCancel()}>
                  閉じる
                </Button>
              </div>
              }
              {isAuth &&
                <>
                  <div style={{textAlign:"start"}}>{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんを承認しますか？</div>
                  <Button key="cancel" onClick={() => updateIsAuth()}>
                    はい
                  </Button>
                  <Button key="cancel" onClick={() => handleCancel()}>
                    いいえ
                  </Button>
                </>
              }
              {isDelete &&
                <>
                  <div style={{textAlign:"start"}}>{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんを本当に削除しますか？</div>
                  <Button key="cancel" onClick={() => deleteUser()}>
                    はい
                  </Button>
                  <Button key="cancel" onClick={() => handleCancel()}>
                    いいえ
                  </Button>
                </>
              }
              {isBreach &&
                <>
                  {filterUsersArray[selectIndex].isBreach ? (
                    <div style={{textAlign:"start"}}>{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんを通常ユーザーに戻しますか？</div>
                  ) : (
                    <div style={{textAlign:"start"}}>{filterUsersArray[selectIndex].lastName}{filterUsersArray[selectIndex].firstName}さんを違反ユーザーに設定しますか？</div>
                  )}
                  <Button key="cancel" onClick={() => breachUser()}>
                    はい
                  </Button>
                  <Button key="cancel" onClick={() => handleCancel()}>
                    いいえ
                  </Button>
                </>
              }
            </>
          ]}
        >
      </Modal>
      {/* <Loading loading={isLoad}/> */}
      <div>
        <div style={{width:"100%",background:"#aaa",textAlign:"end",padding:"5px 0"}}>
          <Select
            style={{minWidth:"100px",marginRight:"10px"}}
            onChange={e=>handleStatus(e)}
            value={status}
          >
            {statusArray.map((item) => (
                <Option value={item.key}><span style={{fontSize:"120%"}}>{item.value}</span></Option>
            ))}
          </Select>
        </div>
        <div  style={{overflowX:"scroll"}}>
          <Table dataSource={filterUsersArray} columns={columns} bordered scroll={{ x: true, y:adjustedHeight }} 
            pagination={{ position: ['topRight','bottomRight'],
              current: currentPage,
              pageSize: pageSize,
              total: filterUsersArray.length
            }} 
            onChange={handleTableChange}/>
        </div>
        </div>
    </>
   
  );
};

export default UsersListAdminTable;

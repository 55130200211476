import './App.css';
import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';



const FriendAdd = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referralCode');
  useEffect(()=>{
    startLINEAuth();
  },[]);

async function startLINEAuth(){
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_API_DIR}/login/callback/first`);
  const state = generateRandomString(8);
  const scope = 'profile openid';
  const authUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}_${referralCode}&scope=${scope}`;
  window.location.href = authUrl;
}

function generateRandomString(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

  return (
    <></>
  );
};

export default FriendAdd;
